import Vue from "vue";
import VueRouter from "vue-router";

import loadEvents from "../utils/loading";
import store from '../store'

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("../views/Home"),
    },
    {
        path: "/track",
        name: "track",
        component: () => import("../views/Track"),
    },
    {
        path: "/price",
        name: "price",
        component: () => import("../views/Price"),
    },
    {
        path: "/",
        name: "Home",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
        // chilidren: [{
        //     path: '',
        //     name: '',
        //     component: () =>
        //         import ( /* webpackChunkName: "home" */ "../views/Home.vue"),
        // }, ]
    },
    {
        // about-us
        path: "/:slug",
        name: "page",
        component: () => import("../views/AboutUs.vue"),
    },
    {
        // about-us
        path: "/article/:slug",
        name: "article",
        component: () => import("../views/Article.vue"),
    },
];

let head = document.getElementsByTagName("head");
let meta = document.createElement("meta");
meta.name = "referrer";
//根据实际情况修改referrer的值，可选值参考上文
meta.content = "unsafe-url";
head[0].appendChild(meta);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

let loads = new loadEvents();
router.beforeEach((to, from, next) => {
    loads.open();
    next();
});

router.afterEach(() => {
    loads.close();

    setTimeout(() => {
        if (store.state.container.title) {
            document.title =
                store.state.container.title +
                "-" +
                store.state.langData['site.vision'];
        } else {
            document.title = store.state.langData['site.vision'];
        }
    }, 100);
});

export default router;
