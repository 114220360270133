<template>
  <div class="header paddingBox">
    <NavLogo></NavLogo>
    <NavMenu></NavMenu>
    <NavRight></NavRight>
    <NavBarSmall></NavBarSmall>
  </div>
</template>
<script>
// import navbar from '../components/common/navbar/NavBar.vue';

// 导航logo
import NavLogo from "./NavLogo.vue";
// 导航菜单
import NavMenu from "./NavMenu.vue";
// 导航右边登录注册
import NavRight from "./NavRight.vue";

// 小型导航菜单
import NavBarSmall from "./NavBarSmall.vue";
export default {
  components: {
    // navbar,
    // logo图标
    NavLogo,
    // 导航菜单
    NavMenu,
    // 导航右边部分
    NavRight,
    // 小型菜单栏
    NavBarSmall,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.header {
  width: 100%;
  height: 7.5rem;
  padding: 0 5.208%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1366px) {
  .header {
    padding: 0 2.25rem;
    /* flex-wrap: wrap;
      align-items: center;
      justify-content: center; */
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0 2.25rem;
    /* flex-wrap: wrap; */
    /* align-items: center; */
    /* justify-content: center; */
  }
}

@media screen and (max-width: 630px) {
  .header {
    /* padding: 0 2.25rem; */
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}
</style>
