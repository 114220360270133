import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 导入elementui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// 图片懒加载
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
  loading: require("./assets/img/All/loading.gif"),
  error: require("./assets/img/All/loading.gif"),
});

// 导入全局样式表
import "./assets/css/base.css";

Vue.config.productionTip = false;

// 设置浏览器标题
Vue.directive("title", {
  inserted: function (el) {
    document.title = el.dataset.title;
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
