import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 语言包
    langData: {},
    // 菜单按钮
    menuList: [],
    // 主页内容
    container: [],
    // 底部列表
    footerList: [],
    // logo和版权
    logoAndRight: {},
    // 联系我们
    contact: {},
    isChina: true,
  },
  mutations: {
    // 获取语言包
    getLang(state, data) {
      state.langData = data;
    },
    getLanguage(state, lang) {
      // 只有严格等于ru
      state.isChina = lang !== "ru";
    },
    // 菜单按钮
    getMenuList(state, menuList) {
      state.menuList = menuList;
    },
    // 主页内容
    getContainer(state, container) {
      state.container = container;
    },
    // 底部列表
    getFooterList(state, footerList) {
      state.footerList = footerList;
    },
    // logo和版权
    getLogoAndRight(state, logoAndRight) {
      state.logoAndRight = logoAndRight;
    },
    // 联系我们
    getContact(state, contact) {
      state.contact = contact;
    },
  },
  actions: {},
  modules: {},
});

// body
// Blob
// BufferSource
// FormData
// URLSearchParams
// USVString
// init
// status
// statusText
// headers
// Response实现了body接口， 所以， 在实例化Response时， 可以调用Body.blob()、 body.formData()、 body.json()、 body.text() 序列化返回值， 返回值是一个Promise。 具体实现方法如下所示：

// var blobReader = new Response(blob).json()
// blobReader.then(res => {
//     console.log(res)
// })
