<template>
  <!-- 底部组件 -->
  <div class="footer">
    <div class="topFooter">
      <!-- 左边部分 -->
      <div class="leftItem" v-if="footerList.length != 0">
        <div class="itemBox" v-for="(item, index) in footerList" :key="index">
          <h4>{{ item.name }}</h4>
          <span class="spanBorder"></span>
          <div class="listItem">
            <p v-for="(item2, index) in item.items" :key="index">
              <a :href="item2.url" :target="item2.target">{{ item2.name }}</a>
            </p>
          </div>
        </div>
      </div>
      <!-- 右边部分 -->
      <!-- <div>
        {{ contact }}
      </div> -->
      <div class="rightItem">
        <h4>{{ contact.title }}</h4>
        <span class="spanBorder"></span>
        <p v-html="contact.content"></p>
      </div>
    </div>
    <div class="bottomFooter">
      <p v-html="logoAndRight['site.beian.cn'] + visit">
      </p>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      visit: '<img src="https://ru-yuan.cn/pv.svg" />'
    };
  },
  methods: {},
  computed: {
    ...mapState(["footerList", "logoAndRight", "contact"]),
  },
};
</script>
<style lang="less" scoped>
.footer {
  padding: 3.125rem 6.25rem 0 6.25rem;
  padding: 2.6041% 5.2083%;
  width: 100%;
  box-sizing: border-box;
  background: #f3f7fa;

  // 上半部分footer
  .topFooter {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    // 左边
    .leftItem {
      width: 50%;

      .itemBox {
        margin-bottom: 3.125rem;
        margin-right: 3.75rem;

        h4 {
          font-size: 1.375rem;
          line-height: 2rem;
          color: #001725;
          text-align: left;
        }

        .spanBorder {
          display: block;
          width: 1.375rem;
          border-bottom: 3px solid #1a97e2;
          margin: 1.25rem 0;
        }

        .listItem {
          display: flex;
          align-items: center;
          /* justify-content: start; */
          color: #3c4f5a;

          p {
            margin-right: 1rem;
          }
        }
      }
    }

    // 右边
    /deep/.rightItem {
      width: 50%;

      p {
        margin-bottom: 1rem;
      }

      .spanBorder {
        display: block;
        width: 1.375rem;
        border-bottom: 3px solid #1a97e2;
        margin: 1.25rem 0;
      }

      h4 {
        font-size: 1.375rem;
        line-height: 2rem;
        color: #001725;
        text-align: left;

        /* &:after {
            content: "";
            width: 1.375rem;
            display: block;
            border-bottom: 3px solid #1a97e2;
            margin: 1.125rem 0;
          } */
      }
    }
  }

  // 下半部分footer
  .bottomFooter {
    border-top: 2px solid #d6dbdf;
    margin-top: 1.25rem;

    p {
      line-height: 5rem;
      position: relative;

      span {
        position: absolute;
        top: 11px;
      }
    }
  }

  a {
    // padding-right: 2.6041%;
    color: #3c4f5a;
    text-decoration: none;

    &:hover {
      color: #37a4e7;
    }
  }
}

#embed * {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .footer {
    h4 {
      font-size: 1.125rem !important;
      line-height: 1.5rem !important;
    }

    .spanBorder {
      margin: 0.8rem 0 !important;
    }

    .topFooter {
      flex-wrap: wrap;
      align-items: flex-start;

      .leftItem,
      .rightItem {
        width: 100% !important;

        .itemBox {
          margin-bottom: 1.125rem !important;
          margin-right: 0 !important;

          .listItem {
            flex-wrap: wrap;
            justify-content: start;

            p {
              margin-bottom: 1rem;
            }
          }
        }
      }

      // 右边
      /deep/.rightItem {
        width: 50%;

        p {
          margin-bottom: 1rem;

          img {
            float: none !important;
          }
        }

        /* >p:nth-of-type(1) {
            font-weight: bold;
            font-size: 1.125rem !important;

            &:after {
              content: "";
              width: 1.375rem;
              display: block;
              border-bottom: 3px solid #1a97e2;
              margin: 1.125rem 0;
            }
          } */
      }
    }

    // 下半部分footer
    .bottomFooter {
      margin-top: 2.5rem;

      p {
        line-height: 5rem;
        position: relative;

        span {
          position: absolute;
          top: 11px;
        }
      }
    }

    a {
      // padding-right: 2.6041%;
      color: #3c4f5a;
      text-decoration: none;

      &:hover {
        color: #37a4e7;
        cursor: pointer;
      }
    }
  }

  #embed * {
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .topFooter {
    align-items: flex-start !important;

    .leftItem {
      width: 55% !important;

      .itemBox {
        margin-right: 1.2rem !important;
      }
    }

    .rightItem {
      flex-wrap: wrap;
      width: 45% !important;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .footer {
    .topFooter {
      align-items: flex-start !important;
      justify-content: space-between;

      .leftItem {
        width: 50%;

        .itemBox {
          //   margin-right: 1.25rem !important;
          .listItem {
            justify-content: start;
          }
        }
      }

      .rightItem {
        width: 50%;
        align-items: center;
        flex-wrap: wrap;

        .itemBox {
          margin-right: 0rem !important;
          margin-bottom: 1.25rem !important;
        }
      }
    }
  }
}
</style>
