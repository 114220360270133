import { Loading } from "element-ui";

const loadOption = {
  fullscreen: true,
  lock: true,
  text: "Loading",
  spinner: "el-icon-loading",
  background: "rgba(0, 0, 0, 0.7)",
};

let loadingInstance;

export default class loadEvents {
  constructor(vueThis) {
    this.vm = vueThis;
  }
  open() {
    loadingInstance = Loading.service(loadOption);
  }
  close() {
    loadingInstance.close();
  }
}
