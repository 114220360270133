<template>
  <!-- 头部logo -->
  <div class="logoBox">
    <a href="/">
      <img v-if="$store.state.isChina" :src="logoAndRight['logo.cn']" alt="" />
      <img v-else :src="logoAndRight['logo.ru']" alt="" />
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState(["logoAndRight"]),
  },
};
</script>

<style scoped="scoped" lang="less">
.logoBox {
  box-sizing: border-box;
  // width: 432px;
  /* height: 2.5rem; */
  /* margin-left: 6.25rem; */
  /* transition: all 0.3s; */
  cursor: pointer;
  transform: scale(1);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 0;
  margin-right: 2%;

  img {
    width: 100%;
    /* height: 100%; */
  }
}

/* @media screen and (max-width: 1500px) {
    .logoBox {
      width: 25.75rem !important;
      width: auto !important;
    }
  } */

// @media screen and (max-width: 768px) {
//   .logoBox {
//     width: 18.75rem !important;
//     width: 25.75rem !important;

//     /* width: auto !important; */
//   }
// }

// @media screen and (max-width: 768px) {
//   .logoBox {
//     width: 18.75rem !important;
//     width: 25.75rem !important;

//     /* width: auto !important; */
//   }
// }
</style>
