<template>
  <!-- 语言切换和登录注册盒子 -->
  <div class="languageAndLogin">
    <!-- 语言切换盒子 -->
    <div class="languageBox">
      <div class="language_a" @click="changeHasShow">
        <div class="cn">
          <img v-if="$store.state.isChina" :src="cnImg" alt="" />
          <img v-else :src="cnImg" alt="" />
        </div>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="language_b" v-if="hasShow">
        <ul>
          <li>
            <a href="https://www.ru-yuan.cn/">
              <img src="../../../assets/img/Language/icon2.png" alt="" />
              CHN
            </a>
          </li>
          <li>
            <a href="https://ruyuan.ru">
              <img src="../../../assets/img/Language/icon3.png" alt="" />
              RUS
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="loginBox">
      <!-- 登录 -->
      <div class="login">{{ langData["index.login"] }}</div>
      <!-- 竖线 -->
      <div class="vLine"></div>
      <!-- 注册 -->
      <div class="register">{{ langData["index.register"] }}</div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      // 监听语言选择的显示
      hasShow: false,
      // language: "CHN",
      cnImg: require("../../../assets/img/Language/cn.png"),
    };
  },
  computed: {
    ...mapState(["langData"]),
  },
  methods: {
    changeHasShow() {
      this.hasShow = !this.hasShow;
    },
  },
};
</script>
<style lang="less" scoped>
// 语言切换和登录注册盒子
.languageAndLogin {
  // width: 11.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  margin-left: 2%;

  // 语言切换盒子
  .languageBox {
    position: relative;
    animation: all ease-in 1s;
    display: flex;
    align-items: center;

    // 语言图片显示
    .language_a {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .cn {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;

        /* background: url("../../../assets/img/Language/cn.png");
          background-size: 100% 100%; */
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // 语言下拉样式
    .language_b {
      border: 1px solid #eee;
      border-radius: 5px;
      background: white;
      position: absolute;
      left: -1rem;
      top: 2.25rem;
      width: 8.125rem;
      box-sizing: border-box;
      z-index: 999;

      li {
        padding: 0.625rem 0;
        text-align: center;

        &:hover {
          background: #eee;
        }

        a {
          height: 2.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          color: #333;
          font-weight: bold;

          img {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  // 登录注册盒子
  .loginBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1rem;

    .vLine {
      margin: 0 1rem;
      height: 1.25rem;
      border-right: 1px solid #333;
    }

    .login,
    .register {
      text-align: center;
      /* width: 80px; */
      /* padding-right: 1.25rem; */
      cursor: pointer;

      &:hover {
        color: #37a4e7;
      }
    }

    /* .login {
        border-right: 1px solid #333;
      } */
  }
}

@media screen and (max-width: 630px) {
  .languageAndLogin {
    /* margin: 0 1rem 0 0; */
  }

  .loginBox {
    margin: 0 0.8rem;
  }

  .vLine {
    margin: 0 0.8rem !important;
    height: 1rem !important;
  }
}
</style>
