<template>
  <div class="NavBarSmall">
    <i class="el-icon-menu" style="font-size: 2rem" @click="drawer = true" type="primary">
    </i>
    <el-drawer :visible.sync="drawer" direction="ltr" :with-header="false" size="35%">
      <!-- 导航菜单 -->
      <el-menu class="el-menu-demo" :collapse-transition="false" :unique-opened="true" :default-active="activeIndex"
        text-color="#333" active-text-color="#37a4e7">
        <!-- 开启路由跳转模式router -->
        <!-- 菜单模板区域 -->
        <div v-for="item in menuList" :key="item.id">
          <el-submenu :index="item.id + ''" v-if="item.items">
            <template slot="title">
              <span slot="title">{{ item.name }}</span>
              <i class="bottomBorder"></i>
            </template>

            <div v-for="subItem in item.items" :key="subItem.id">
              <el-submenu :index="subItem.id + ''" v-if="subItem.items">
                <template slot="title">
                  <span slot="title">{{ subItem.name }}</span>
                  <i class="bottomBorder"></i>
                </template>

                <el-menu-item :index="children.url+ ''" v-for="children in subItem.items" :key="children.id">
                  <template slot="title">
                    <a :href="children.url" :target="children.target"> <span slot="title">{{ children.name }}</span></a>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-menu-item :index="subItem.url+ ''" v-else :key="subItem.id">

                <template slot="title">
                  <a :href="subItem.url" :target="subItem.target"> <span slot="title">{{ subItem.name }}</span></a>
                </template>
              </el-menu-item>
            </div>
          </el-submenu>

          <el-menu-item v-else>
            <a :href="item.url" :target="item.target">
              <span slot="title">{{ item.name }}</span>
              <i class="bottomBorder"></i>
            </a>
          </el-menu-item>
        </div>
      </el-menu>
    </el-drawer>
  </div>
</template>
<script>
  import NavRight from "./NavRight";
  import { mapState } from "vuex";
  export default {
    component: {
      NavRight,
    },
    data() {
      return {
        // 被激活导航地址
        activeIndex: "1",
        activePath: "",
        drawer: false,
      };
    },
    computed: {
      ...mapState(["menuList"]),
    },
    methods: {

    },
  };
</script>
<style lang="less" scoped>
  // .el-menu-demo {
  //   border: none !important;
  //   background: transparent !important;
  // }

  // .el-menu-demo > li {
  //   margin-right: 1.25rem;
  // }

  // .el-menu-item {
  //   // color: white !important;
  //   border: none !important;

  //   &:hover {
  //     color: #89ba43 !important;
  //     background: none !important;
  //   }
  // }

  // /deep/.el-submenu__title:hover {
  //   color: #89ba43 !important;
  // }

  .el-menu--horizontal>.el-menu-item.is-active {
    background: none !important;
  }

  @media screen and (min-width: 1025px) {
    .NavBarSmall {
      display: none !important;
    }
  }
</style>