<template>
  <!-- 菜单栏 -->
  <div class="menuBox">
    <!-- <div v-html="menuList"></div> -->
    <div
      v-for="(item, index) in menuList"
      :key="index"
      class="navbaritem"
      :class="$route.path == item.url ? 'activeItem' : ''"
      @mouseenter="show = index"
      @mouseleave="show = false"
    >
      <a :href="item.url" :target="item.target">
        <slot>{{ item.name }}</slot>
        <div class="bottomBorder"></div>
      </a>
      <!-- v-show="show === index" -->
      <div class="showBox" v-show="show === index">
        <div
          :class="item.items[0].items ? 'subMenuBox' : 'subMenuBox2'"
          ref="subMenuBox"
          v-if="item.items"
        >
          <div v-for="item2 in item.items" :key="item2.id" class="subMenuItem">
            <div v-if="item2.items">
              <div>
                <strong>
                  <a :href="item2.url" :target="item.target">{{
                    item2.name
                  }}</a>
                </strong>
                <ul>
                  <li
                    v-for="subItem in item2.items"
                    :key="subItem.id"
                    class="liItem"
                  >
                    <a :href="subItem.url" :target="item.target">{{
                      subItem.name
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else>
              <ul>
                <li class="liItem">
                  <a :href="item2.url" :target="item.target">{{
                    item2.name
                  }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState(["menuList"]),
  },
};
</script>

<style scoped="scoped" lang="less">
.menuBox {
  width: 36.625rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.liItem a:hover {
  color: #37a4e7;
}

.navbaritem {
  position: relative;
  font-size: 1.25rem;
  height: 100%;
  //   margin: 0 0.9375rem;
  // padding: 0 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #2f2f2f;
  transition: all 0.3s;
  margin: 0 0.5rem;

  &:hover {
    color: #37a4e7;

    .bottomBorder {
      width: 100%;
    }
  }

  i {
    margin-right: 0.5rem;
    font-size: 1rem;
  }

  .bottomBorder {
    width: 0;
    height: 0.1875rem;
    background-color: #37a4e7;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: all 0.5s;
  }

  a {
    text-decoration: 0;
    color: #2f2f2f;
  }
}

.activeItem {
  color: #37a4e7;

  .bottomBorder {
    width: 100%;
  }
}

.showBox {
  position: absolute;
  top: 3rem;
  z-index: 1999;
}

.subMenuBox2 {
  background: white;
  border-radius: 5px;
  border: 1px solid #eee;
  width: 8rem;
  padding: 1rem 0rem;
  min-height: 10rem;
  color: black;
  font-size: 0.875rem;
  text-align: center;

  .liItem {
    margin-top: 1rem;

    &:hover {
      color: #37a4e7;
    }
  }
}

// 子菜单盒子
.subMenuBox {
  background: white;
  border-radius: 5px;
  border: 1px solid #eee;

  width: 15rem;
  min-height: 10rem;
  color: black;
  padding: 1rem 0rem;
  display: flex;
  // align-items: center;
  justify-content: space-around;

  .subMenuItem {
    font-size: 0.875rem;

    strong {
      font-size: 1.125rem;
      font-weight: normal;
    }

    .liItem {
      margin-top: 1rem;

      &:hover {
        color: #37a4e7;
      }
    }
  }
}

@media screen and (max-width: 1120px) {
  .menuBox {
    width: 30.625rem;
  }
}

@media screen and (max-width: 1024px) {
  .menuBox {
    display: none;
  }
}
</style>
