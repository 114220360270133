<template>
  <div id="app">
    <!-- 头部组件 -->
    <MyHeader></MyHeader>
    <router-view> </router-view>
    <!-- 尾部组件 -->
    <MyFooter></MyFooter>
  </div>
</template>

<script>
  // 头部组件
  import MyHeader from "./components/common/navbar/Header";
  // 尾部组件
  import MyFooter from "./components/common/footer/Footer";

  // 自己封装的axios
  import axios from "./assets/axios/axios";
  // 原生axios
  // import Axios from "axios";
  export default {
    components: {
      MyHeader,
      MyFooter,
    },
    name: "App",
    data() {
      return {};
    },
    created() {
      // //在页面加载时读取sessionStorage里的状态信息
      // if (sessionStorage.getItem("store")) {
      //   this.$store.replaceState(
      //     Object.assign(
      //       {},
      //       this.$store.state,
      //       JSON.parse(sessionStorage.getItem("store"))
      //     )
      //   );
      //   sessionStorage.removeItem("store");
      // }
      //
      // //在页面刷新时将vuex里的信息保存到sessionStorage里
      // window.addEventListener("beforeunload", () => {
      //   sessionStorage.setItem("store", JSON.stringify(this.$store.state));
      // });
      // 获取语言
      this.getLang();
      // 获取菜单栏
      this.getMenuList();
      // 获取底部菜单
      this.getFooterList();
      // 获取logo和版权
      this.getLogoAndRight();
      // 获取联系我们信息
      this.getContact();
    },
    mounted() { },
    methods: {
      // 获取语言
      getLang() {
        axios.getLang().then((res) => {
          const { data } = res.data;
          this.$store.commit("getLang", data);
        });
      },
      // 获取菜单栏
      getMenuList() {
        axios.getMenuList().then((res) => {
          const { data } = res.data;
          this.$store.commit("getMenuList", data);
        });
      },
      // 获取底部菜单栏
      getFooterList() {
        axios.getFooterList().then((res) => {
          const { data } = res.data;
          this.$store.commit("getFooterList", data);
          // console.log(data);
        });
      },

      // 获取logo和版权
      getLogoAndRight() {
        axios.getLogoAndRight().then((res) => {
          const { data, lang } = res.data;
          this.$store.commit("getLanguage", lang);
          let items = {};
          data.forEach((key) => {
            items[key.code] = key.name;
          });
          // console.log(data);
          this.$store.commit("getLogoAndRight", items);
        });
      },
      // 获取联系我们信息
      getContact() {
        axios.getContact().then((res) => {
          const { data } = res.data;
          // console.log(data);
          this.$store.commit("getContact", data);
        });
      },
    },
  };
</script>
<style lang="less">
  #app {
    max-width: 1920px;
    margin: 0 auto;
  }
</style>