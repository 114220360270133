import axios from "axios";
var instance = axios.create({
  baseURL: "/api/",
  timeout: 100000, // 设置请求超时连接时间,
  headers: {
    "Cache-Control": "no-cache",
  },
});

// 通过axios请求拦截器添加token,保证拥有获取数据的权限
instance.interceptors.request.use((config) => {
  const tokenStr = window.sessionStorage.getItem("token");
  // alert(tokenStr)
  if (tokenStr) {
    // config.headers.Authorization = tokenStr
    config.headers["token"] = tokenStr;
  }
  (error) => {
    return Promise.reject(error);
  };
  return config;
});
// 响应拦截器
// instance.interceptors.response.use(config => {
//         return config.data
//     })
// 调用request后返回创建的axios,(返回的会是一个promise对象)
class Axios {
  // 获取语言
  async getLang() {
    return await instance.get("lang");
  }

  // 获取菜单栏
  async getMenuList() {
    return await instance.get("nav?name=Menu");
  }
  // 获取底部菜单栏
  async getFooterList() {
    return await instance.get("nav?name=Help");
  }
  // 首页内容
  async getContainer(p) {
    return await instance.get(`index?p=${p}`);
  }
  // 关于我们 参数about-us
  async getSlug(params, p) {
    return await instance.get(`page/${params}?p=${p}`);
  }
  // 关于我们 参数about-us
  async getArticle(params, p) {
    return await instance.get(`article/${params}?p=${p}`);
  }
  // logo和版权
  async getLogoAndRight() {
    return await instance.get(`config`);
  }
  // 联系我们
  // contact
  async getContact() {
    return await instance.get(`page/Contact`);
  }
  // Track 页面
  // Track内容
  async getTrackContent() {
    return await instance.get(`page/Track`);
  }
  // 运单查询
  async getTrackInfo(params) {
    return await instance.get(`track?waybillNum=${params}`);
  }

  // Price页面
  // Price内容
  async getPriceContent() {
    return await instance.get(`page/Price`);
  }
  // 到达国家查询
  async getDes() {
    return await instance.get(`des`);
  }
  // 快递渠道查询
  async getModel() {
    return await instance.get(`model`);
  }
  // 时效价格查询
  async getPrice(params) {
    return await instance.post(`price`, params);
  }
}

export default new Axios();
